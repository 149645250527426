<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated" data-app>
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile
      :alert-count-chat.sync="AlertCountChat"
      :alert-count-incident.sync="AlertCountIncident"
    />
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header desktop -->
        <KTHeader
          :alert-count-chat.sync="AlertCountChat"
          :alert-count-incident.sync="AlertCountIncident"
        />
        <!-- end:: Header desktop-->

        <!-- begin:: Content -->
        <div
          class="content d-flex flex-column flex-column-fluid"
          :class="
            $route.name === 'Home'
              ? 'content-manager-home bgi-size-cover bgi-position-y-center bgi-position-x-center'
              : 'content-manager'
          "
        >
          <!-- begin:: Content Body -->
          <div class="content-body d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <div class="d-lg-flex flex-row-fluid">
                <!-- begin:: Aside Left -->
                <KTAside v-if="asideEnabled"></KTAside>
                <!-- end:: Aside Left -->
                <div class="content-wrapper flex-row-fluid">
                  <transition name="fade-in-up">
                    <router-view />
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>

    <KTScrollTop></KTScrollTop>
  </div>
</template>

<!-- Load Manager custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/manager/Base.scss";
</style>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/FooterExtended.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import ShipmentService from "@/core/services/api/v2/shipment.service";

export default {
  name: "LayoutManager",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTScrollTop,
    Loader
  },

  data() {
    return {
      UpdateAlertsIntervalMilis: 30000,
      UpdateAlertsIntervalID: 0,
      UpdatingAlerts: false,
      AlertCountChat: 0,
      AlertCountIncident: 0
    };
  },

  beforeMount() {
    // Show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // Initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },

  created() {
    // Get new alerts every X time
    this.updateAlerts();
    this.UpdateAlertsIntervalID = setInterval(
      this.updateAlerts,
      this.UpdateAlertsIntervalMilis
    );
  },
  beforeDestroy() {
    clearInterval(this.UpdateAlertsIntervalID);
  },

  mounted() {
    // Check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "Landing" }).catch(() => {
        // To avoid some ugly messages about redirect
        // console.info(error.message);
      });
    }

    // Remove page loader
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
  },

  updated() {
    // Re-check if current user is authenticated (maybe expired after mount)
    if (!this.isAuthenticated) {
      this.$router.push({ name: "Landing" }).catch(() => {
        // To avoid some ugly messages about redirect
        // console.info(error.message);
      });
    }
  },

  methods: {
    updateAlerts() {
      if (this.isAuthenticated && !this.UpdatingAlerts) {
        this.UpdatingAlerts = true;

        ShipmentService.getAllChatsAlertCount().then(response => {
          this.AlertCountChat = response.Total;

          ShipmentService.getAllUnfinishedIncidentsCount().then(response => {
            this.AlertCountIncident = response;
            this.UpdatingAlerts = false;
          });
        });
      }
    },

    footerLayout(type) {
      return this.layoutConfig("footer.layout") === type;
    }
  },

  computed: {
    ...mapGetters(["isAuthenticated", "pageTitle", "layoutConfig"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
